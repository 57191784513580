import {BrowserRouter, Routes, Route} from "react-router-dom";

import logo from './logo.svg';
import './App.css';

import TitleBar from "./pages/TitleBar";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import ProjectPage from "./pages/ProjectPage";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/projects" element={<ProjectPage />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;

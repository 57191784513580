import './about.css';

import TitleBar from "./TitleBar";
import * as page from "./Page.js";

import Darien from "../res/darien.jpg";
import GithubLogo from "../res/github-mark.svg";
import DocIcon from "../res/document.svg";
import LinkedInIcon from "../res/LI-In-Bug.png";

function AboutPage() {
    return (
        <div className="home home_Root pages_HomePage">
            <TitleBar />
            <page.Page>
                <page.PageTitle>About Me</page.PageTitle>
                <ProfileImage img={""}>Darien Campbell</ProfileImage>
                <div className="pages_ChipRow">
                    <page.Chip href="https://github.com/darienac" icon={GithubLogo}>Github</page.Chip>
                    <page.Chip href="/resume" icon={DocIcon}>Resume</page.Chip>
                    <page.Chip href="https://www.linkedin.com/in/darien-campbell/" icon={LinkedInIcon}>LinkedIn</page.Chip>
                </div>
            </page.Page>
        </div>
    );
}

function ProfileImage(props) {
    return (
        <div className="about_ProfileImage">
            <img src={props.img} />
            <div>{props.children}</div>
        </div>
    );
}

export default AboutPage;
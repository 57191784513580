import TitleBar from "./TitleBar";

import * as page from "./Page.js";

function ProjectPage() {
    return (
        <div className="home home_Root pages_HomePage">
            <TitleBar />
            <page.Page>
                <page.PageTitle>My Projects</page.PageTitle>
            </page.Page>
        </div>
    );
}

export default ProjectPage;
import TitleBar from "./TitleBar";

import Sunset from "../res/sunset.jpg";
import DarienCT from "../res/darienct.jpg";
import Tetris from "../res/tetris.png";

import * as page from "./Page.js";

function HomePage() {
    return (
        <div className="home home_Root pages_HomePage">
            <TitleBar />
            <page.Page>
                <page.PageTitle>Home</page.PageTitle>
                <page.PageImagePane src={Sunset} title="Welcome to my Website!">
                    This website is the hub for all my current projects as well as info about me. More info can be found about me in the "About Me" section and several links are provided below to different sites and projects.
                </page.PageImagePane>
                <page.PageTitle>Quick Links</page.PageTitle>
                <div className="pages_BentoGrid">
                    <page.BentoBox to="/about" img={DarienCT} grow={1}>About Me</page.BentoBox>
                    <page.BentoBox to="/projects" img={Tetris} grow={6}>My Projects</page.BentoBox>
                </div>
            </page.Page>
        </div>
    );
}

export default HomePage;
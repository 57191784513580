import './TitleBar.css';
import './pages.css';

import {NavLink} from "react-router-dom";

function Page(props) {
    return <div className="pages_Page">{props.children}</div>
}

function PageTitle(props) {
    return (
        <div className="pages_PageTitle">
            {props.children}<div />
        </div>
    );
}

function PageImagePane(props) {
    return (
        <div className="pages_PageImagePane">
            <img src={props.src} />
            <div>
                <h1>{props.title}</h1>
                <p>{props.children}</p>
            </div>
        </div>
    );
}

function BentoBox(props) {
    return (
        <NavLink to={props.to} className="pages_BentoBox" style={{backgroundImage: "url(" + props.img + ")", flexGrow: props.grow}}>
            <div>{props.children}</div>
        </NavLink>
    );
}

function Chip(props) {
    return (
        <a className="pages_Chip" href={props.href}>
            <img src={props.icon} />
            <div>{props.children}</div>
        </a>
    );
}

export {Page, PageTitle, PageImagePane, BentoBox, Chip};